import { authenticatedRequest } from "./apiUtils";

export const fetchUserId = async () => {
  try {
    const data = await authenticatedRequest('/users/id', {
      method: "GET",
    });

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately
  }
};

export const fetchUserDetails = async (userId: number) => {
  try {
    const data = await authenticatedRequest(`/users/${userId}`, {
      method: "GET",
    });

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately
  }
};


export const fetchOrganization = async (orgId: number) => {
  try {
    const data = await authenticatedRequest(`/organizations/${orgId}`, {
      method: "GET",
    });

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately
  }
};

export const fetchOrganizationTeams = async (orgId: number) => {
  try {
    const data = await authenticatedRequest(`/organizations/${orgId}/teams/`, {
      method: "GET",
    });

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately
  }
};

export const fetchOrganizationUsers = async (orgId: number) => {
  try {
    const data = await authenticatedRequest(`/organizations/${orgId}/users/`, {
      method: "GET",
    });

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately    
  }
};

export const createUserInvite = async (orgId: any, email: string, role: any, _: any) => {
  try {
    const data = await authenticatedRequest(`/organizations/${orgId}/invitelist/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, role }),
    });

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately
  }
};

export const sendUserInvite = async (orgId: any, inviteId: any) => {
  try {
    const data = await authenticatedRequest(`/organizations/${orgId}/send-invitations/${inviteId}/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; //Handle fetch error appropriately
  }
};


export const deleteOrgUser = async (orgId: any, userId: any) => {
  try {
    const data = await authenticatedRequest(`/organizations/${orgId}/users/${userId}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ active: false }),
    });

    return data
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately
  }
};

export const unArchiveUser = async (orgId: any, userId: any) => {
  try {
    const data = await authenticatedRequest(`/organizations/${orgId}/users/${userId}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ active: true }),
    });

    return data
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; //Handle fetch error appropriately    
  }
};

export const updateOrgUser = async (orgId: any, userId: any, _: any, role: any, team: any) => {
  try {
    const data = await authenticatedRequest(`/organizations/${orgId}/users/${userId}/`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ role, team }),
    });

    return data;
  } catch (error) {
    return false; //Handle fetch error appropriately
  }
};


export const fetchInviteList = async (orgId: number) => {
  try {
    const data = await authenticatedRequest(`/organizations/${orgId}/invitelist/`, {
      method: "GET",
    });

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; //Handle fetch error appropriately    
  }
};

export const fetchInviteUser = async (orgId: number, id: number) => {
  try {
    const data = await authenticatedRequest(`/organizations/${orgId}/invitelist/${id}`, {
      method: "GET",
    });

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; //Handle fetch error appropriately    
  }
};

export const updateInviteUser = async (orgId: any, id: any, role: any, email: any) => {
  try {
    const data = await authenticatedRequest(`/organizations/${orgId}/invitelist/${id}/`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ role, email }),
    });

    return data;
  } catch (error) {
    return false; //Handle fetch error appropriately
  }
};

export const fetchLicenses = async (tenantId: string | null) => {
  try {
    const baseURL = process.env.REACT_APP_URL || "https://app.dev.xylo.ai";
    if (!baseURL) {
      throw new Error("REACT_APP_URL is not defined");
    }


    const endpoint = `/licenses`;


    const url = new URL(endpoint, baseURL);
    if (tenantId) {
      url.searchParams.append('tenant_id', tenantId);
    }



    const data = await authenticatedRequest(url.pathname + url.search, {
      method: "GET"
    }, baseURL);

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately
  }
};


export const updateLicense = async (licenseId: any, status: any) => {
  const baseURL = process.env.REACT_APP_URL || "https://app.dev.xylo.ai";
  if (!baseURL) {
    throw new Error("REACT_APP_URL is not defined");
  }

  try {
    const data = await authenticatedRequest(`/licenses/${licenseId}/`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ status: status }),
    }, baseURL);

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately
  }
}

export const createLicenses = async (email: string | null, license_type: string | null, status: string | null, tenantId: string | null) => {
  try {
    const baseURL = process.env.REACT_APP_URL || "https://app.dev.xylo.ai";
    if (!baseURL) {
      throw new Error("REACT_APP_URL is not defined");
    }


    const endpoint = `/licenses/`;


    const url = new URL(endpoint, baseURL);
    if (tenantId) {
      url.searchParams.append('tenant_id', tenantId);
    }



    const data = await authenticatedRequest(url.pathname + url.search, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, license_type: license_type, status: status, tenant_id: tenantId }),
    }, baseURL);


    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately
  }
};
